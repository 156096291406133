import { Map, fromJS } from "immutable";
import {
  INIT,
  LOGOUT,
  LOGIN_SUCCESS,
  SAVE_USER_TOKEN,
  SAVE_USER_INFO,
  UPDATE_USER_IMAGE,
  SAVE_USER_ROLES,
  SESSION_ID,
  SOCKET_CONNECTION,
} from "../../actions/actionConstants";
import { loadUser } from "../../utils/users";

const initialState = {
  user: fromJS(loadUser()),
};
const initialImmutableState = fromJS(initialState);
export default function reducer(state = initialImmutableState, action = {}) {
  switch (action.type) {
    case INIT:
      return state;
    case LOGIN_SUCCESS:
      return state.withMutations((mutableState) => {
        mutableState.setIn(["user", "loggedIn"], true);
      });
    case SAVE_USER_TOKEN:
      return state.withMutations((mutableState) => {
        mutableState.setIn(["user", "token"], action.token);
      });
    case SAVE_USER_INFO:
      return state.withMutations((mutableState) => {
        mutableState.setIn(["user", "info"], fromJS(action.userInfo));
      });
    case SAVE_USER_ROLES:
      return state.setIn(["user", "roles"], fromJS(action.userRoles));
    case UPDATE_USER_IMAGE:
      return state.withMutations((mutableState) => {
        mutableState.setIn(["user", "info", "image"], action.image);
      });
    case LOGOUT:
      return state.withMutations((mutableState) => {
        mutableState.setIn(["user", "info"], Map());
        mutableState.removeIn(["user", "token"]);
        mutableState.setIn(["user", "loggedIn"], false);
        mutableState.removeIn(["user", "roles"]);
      });
    case SESSION_ID:
      return state.withMutations((mutableState) => {
        mutableState.setIn(["user", "sessionId"], action.sessionId);
      });
    case SOCKET_CONNECTION:
      return state.withMutations((mutableState) => {
        mutableState.setIn(
          ["user", "socketConnection"],
          action.socketConnection
        );
      });
    default:
      return state;
  }
}
