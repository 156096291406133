import React from "react";
import { Prompt } from "react-router-dom";
import { PropTypes } from "prop-types";
import classNames from "classnames";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { GuideSlider } from "components";
import {
  toggleAction,
  openAction,
  playTransitionAction,
  setISONumberAction,
} from "actions/UiActions";
import LeftSidebarLayout from "./layouts/LeftSidebarLayout";
import RightSidebarLayout from "./layouts/RightSidebarLayout";
import LeftSidebarBigLayout from "./layouts/LeftSidebarBigLayout";
import DropMenuLayout from "./layouts/DropMenuLayout";
import MegaMenuLayout from "./layouts/MegaMenuLayout";
import styles from "./appStyles-jss";
import { getCookie } from "../Pages/Chat/utils/cookiesHelper";

class Dashboard extends React.Component {
  // Initial header style
  state = {
    openGuide: JSON.parse(getCookie("isGuided")) || false,
  };

  componentDidUpdate(prevProps) {
    const { confirmNavigation } = this.props;
    if (confirmNavigation && !prevProps.confirmNavigation) {
      window.addEventListener("beforeunload", this.onUnload);
    } else if (!confirmNavigation && prevProps.confirmNavigation) {
      window.removeEventListener("beforeunload", this.onUnload);
    }
  }

  componentDidMount() {
    const { history, initialOpen, loadTransition, fetchISONumber } = this.props;

    // Set expanded sidebar menu
    const currentPath = history.location.pathname;
    initialOpen(currentPath);
    // Play page transition
    loadTransition(true);

    // Execute all arguments when page changes
    this.unlisten = history.listen(() => {
      window.scrollTo(0, 0);
      setTimeout(() => {
        loadTransition(true);
      }, 500);
    });

    // New API call
    // this.fetchISONumber();
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onUnload);
  }

  onUnload = (ev) => {
    ev.preventDefault();
    // eslint-disable-next-line no-param-reassign
    ev.returnValue = "changes not saved";
  };

  handleNavigation = function* handleNavigation() {
    const { confirmNavigation } = this.props;
    while (true) {
      const result =
        !confirmNavigation ||
        window.confirm("changes not saved, leave anyway?");
      yield result;
      yield result;
    }
  };

  handleOpenGuide = () => {
    this.setState({ openGuide: true });
  };

  handleCloseGuide = () => {
    this.setState({ openGuide: false });
    localStorage.setItem("isGuided", false);
  };

  // fetchISONumber = async () => {
  //   try {
  //     const response = await fetch("YOUR_API_ENDPOINT_HERE");
  //     const data = await response.json();
  //     this.props.setISONumber(data.isoNumber);
  //   } catch (error) {
  //     console.error("Error fetching ISO number:", error);
  //   }
  // };

  render() {
    const {
      classes,
      children,
      toggleDrawer,
      sidebarOpen,
      loadTransition,
      pageLoaded,
      mode,
      history,
      gradient,
      deco,
      bgPosition,
      layout,
      changeMode,
      user,
      dataMenu: immutableDataMenu,
    } = this.props;
    const dataMenu = immutableDataMenu.toJS();
    const { openGuide } = this.state;
    const parts = history.location.pathname.split("/");
    const place = parts[parts.length - 1].replace(/-/g, " ");
    const navigationGenerator = this.handleNavigation();
    const handleTransition = (isLoaded) =>
      navigationGenerator.next().value && loadTransition(isLoaded);
    return (
      <div
        className={classNames(
          classes.appFrameInner,
          layout === "top-navigation" || layout === "mega-menu"
            ? classes.topNav
            : classes.sideNav,
          mode === "dark" ? "dark-mode" : "light-mode"
        )}
      >
        <Prompt
          message={() => navigationGenerator.next().value} // accepts
        />
        <GuideSlider openGuide={openGuide} closeGuide={this.handleCloseGuide} />
        {
          /* Left Sidebar Layout */
          layout === "left-sidebar" && (
            <LeftSidebarLayout
              history={history}
              toggleDrawer={toggleDrawer}
              loadTransition={handleTransition}
              changeMode={changeMode}
              sidebarOpen={sidebarOpen}
              pageLoaded={pageLoaded}
              mode={mode}
              gradient={gradient}
              deco={deco}
              bgPosition={bgPosition}
              place={place}
              // titleException={titleException}
              handleOpenGuide={this.handleOpenGuide}
              user={user}
              dataMenu={dataMenu}
            >
              {children}
            </LeftSidebarLayout>
          )
        }
        {
          /* Left Big-Sidebar Layout */
          layout === "big-sidebar" && (
            <LeftSidebarBigLayout
              history={history}
              toggleDrawer={toggleDrawer}
              loadTransition={handleTransition}
              changeMode={changeMode}
              sidebarOpen={sidebarOpen}
              pageLoaded={pageLoaded}
              gradient={gradient}
              deco={deco}
              bgPosition={bgPosition}
              mode={mode}
              place={place}
              // titleException={titleException}
              handleOpenGuide={this.handleOpenGuide}
              user={user}
              dataMenu={dataMenu}
            >
              {children}
            </LeftSidebarBigLayout>
          )
        }
        {
          /* Right Sidebar Layout */
          layout === "right-sidebar" && (
            <RightSidebarLayout
              history={history}
              toggleDrawer={toggleDrawer}
              loadTransition={handleTransition}
              changeMode={changeMode}
              sidebarOpen={sidebarOpen}
              pageLoaded={pageLoaded}
              mode={mode}
              gradient={gradient}
              deco={deco}
              bgPosition={bgPosition}
              place={place}
              // titleException={titleException}
              handleOpenGuide={this.handleOpenGuide}
              user={user}
              dataMenu={dataMenu}
            >
              {children}
            </RightSidebarLayout>
          )
        }
        {
          /* Top Bar with Dropdown Menu */
          layout === "top-navigation" && (
            <DropMenuLayout
              history={history}
              toggleDrawer={toggleDrawer}
              loadTransition={handleTransition}
              changeMode={changeMode}
              sidebarOpen={sidebarOpen}
              pageLoaded={pageLoaded}
              mode={mode}
              gradient={gradient}
              deco={deco}
              bgPosition={bgPosition}
              place={place}
              // titleException={titleException}
              handleOpenGuide={this.handleOpenGuide}
              user={user}
              dataMenu={dataMenu}
            >
              {children}
            </DropMenuLayout>
          )
        }
        {
          /* Top Bar with Mega Menu */
          layout === "mega-menu" && (
            <MegaMenuLayout
              history={history}
              toggleDrawer={toggleDrawer}
              loadTransition={handleTransition}
              changeMode={changeMode}
              sidebarOpen={sidebarOpen}
              pageLoaded={pageLoaded}
              mode={mode}
              gradient={gradient}
              deco={deco}
              bgPosition={bgPosition}
              place={place}
              // titleException={titleException}
              handleOpenGuide={this.handleOpenGuide}
              user={user}
              dataMenu={dataMenu}
            >
              {children}
            </MegaMenuLayout>
          )
        }
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  history: PropTypes.object.isRequired,
  initialOpen: PropTypes.func.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  loadTransition: PropTypes.func.isRequired,
  changeMode: PropTypes.func.isRequired,
  sidebarOpen: PropTypes.bool.isRequired,
  pageLoaded: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  gradient: PropTypes.bool.isRequired,
  deco: PropTypes.bool.isRequired,
  bgPosition: PropTypes.string.isRequired,
  layout: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  dataMenu: PropTypes.array.isRequired,
  confirmNavigation: PropTypes.bool.isRequired,
  setISONumber: PropTypes.func.isRequired,
};

const reducer = "ui";
const mapStateToProps = (state) => ({
  sidebarOpen: state.getIn([reducer, "sidebarOpen"]),
  pageLoaded: state.getIn([reducer, "pageLoaded"]),
  mode: state.getIn([reducer, "type"]),
  gradient: state.getIn([reducer, "gradient"]),
  deco: state.getIn([reducer, "decoration"]),
  layout: state.getIn([reducer, "layout"]),
  bgPosition: state.getIn([reducer, "bgPosition"]),
  dataMenu: state.getIn([reducer, "filteredMenu"]),
  confirmNavigation: state.getIn([reducer, "confirmNavigation"]),
  isoNumber: state.getIn([reducer, "isoNumber"]),
});

const mapDispatchToProps = (dispatch) => ({
  toggleDrawer: () => dispatch(toggleAction),
  initialOpen: bindActionCreators(openAction, dispatch),
  loadTransition: bindActionCreators(playTransitionAction, dispatch),
  setISONumber: bindActionCreators(setISONumberAction, dispatch),
});

const DashboardMaped = connect(mapStateToProps, mapDispatchToProps)(Dashboard);

export default withStyles(styles)(DashboardMaped);
