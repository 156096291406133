import themePalette from "api/palette/themePaletteMode";

const applicationTheme = (color, mode, direction) => ({
  direction,
  palette: {
    type: mode,
    iconColor: mode === "dark" ? "#fff" : "#0B0B0B",
    borderDark: "#ffffff1F",
    borderLight: "#0000001F",
    borderBlueLight: "#E0EBFF",
    primary: themePalette(color, mode).palette.primary,
    secondary: themePalette(color, mode).palette.secondary,
    uniqueColor: "#FF5400",
    uniqueColorHover: "#ee4e00",
    mainGradient: "#9414FF",
    mainSideBar: "#FFF",
    gradient:
      "linear-gradient(92.68deg, #FF5B00 3.9%, #F70072 66.33%, #860CDF 115.92%)",
    gradientBorder:
      "linear-gradient(92.68deg, #FF5B00 3.9%, #F70072 66.33%, #860CDF 115.92%) 1",
    action: {
      hover: mode === "dark" ? "rgba(80,80,80, 0.9)" : "rgba(80,80,80, 0.05)",
      hoverOpacity: 0.05,
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: ["Inter", "General Sans", "Poppins", "Open Sans", "sans-serif"].join(
      ","
    ),
    title: {
      fontWeight: 600,
    },
    medium: {
      fontWeight: 400,
    },
    body2: {
      fontWeight: 400,
    },
    fontWeightMedium: 500,
  },
  shade: {
    light: "0 10px 15px -5px rgba(62, 57, 107, .07)",
  },
  glow: {
    light: `0 2px 20px -5px ${themePalette(color, mode).palette.primary.main}`,
    medium: `0 2px 40px -5px ${themePalette(color, mode).palette.primary.main}`,
    dark: `0 2px 40px 0px ${themePalette(color, mode).palette.primary.main}`,
  },
  rounded: {
    small: "8px",
    medium: "12px",
    semi_medium: "15px",
    big: "24px",
    veryBig: "24px",
  },
  blackColorGradients: {
    veryLightBlack: "rgba(0, 0, 0, 0.12)",
    lightBlack: "rgba(0, 0, 0, 0.32)",
    halfBlack: "rgba(0, 0, 0, 0.54)",
    almostBlack: "rgba(0, 0, 0, 0.87)",
    black: "rgba(0, 0, 0, 1)",
  },
  whiteColorGradients: {
    veryLightWhite: "rgba(255, 255, 255, 0.12)",
    lightWhite: "rgba(255, 255, 255, 0.32)",
    halfWhite: "rgba(255, 255, 255, 0.54)",
    almostWhite: "rgba(255, 255, 255, 0.87)",
    white: "rgba(255, 255, 255, 1)",
  },
  greyColorGradients: {
    grey249: "rgb(249,249,249)",
  },
  shadows:
    mode === "dark"
      ? [
        "none",
        "0px 1px 3px 0px rgba(50,50,50, 0.2),0px 1px 1px 0px rgba(50,50,50, 0.14),0px 2px 1px -1px rgba(50,50,50, 0.12)",
        "0px 1px 5px 0px rgba(50,50,50, 0.2),0px 2px 2px 0px rgba(50,50,50, 0.14),0px 3px 1px -2px rgba(50,50,50, 0.12)",
        "0px 1px 8px 0px rgba(50,50,50, 0.2),0px 3px 4px 0px rgba(50,50,50, 0.14),0px 3px 3px -2px rgba(50,50,50, 0.12)",
        "0px 2px 4px -1px rgba(50,50,50, 0.2),0px 4px 5px 0px rgba(50,50,50, 0.14),0px 1px 10px 0px rgba(50,50,50, 0.12)",
        "0px 3px 5px -1px rgba(50,50,50, 0.2),0px 5px 8px 0px rgba(50,50,50, 0.14),0px 1px 14px 0px rgba(50,50,50, 0.12)",
        "0px 3px 5px -1px rgba(50,50,50, 0.2),0px 6px 10px 0px rgba(50,50,50, 0.14),0px 1px 18px 0px rgba(50,50,50, 0.12)",
        "0px 4px 5px -2px rgba(50,50,50, 0.2),0px 7px 10px 1px rgba(50,50,50, 0.14),0px 2px 16px 1px rgba(50,50,50, 0.12)",
        "0px 5px 5px -3px rgba(50,50,50, 0.2),0px 8px 10px 1px rgba(50,50,50, 0.14),0px 3px 14px 2px rgba(50,50,50, 0.12)",
        "0px 5px 6px -3px rgba(50,50,50, 0.2),0px 9px 12px 1px rgba(50,50,50, 0.14),0px 3px 16px 2px rgba(50,50,50, 0.12)",
        "0px 6px 6px -3px rgba(50,50,50, 0.2),0px 10px 14px 1px rgba(50,50,50, 0.14),0px 4px 18px 3px rgba(50,50,50, 0.12)",
        "0px 6px 7px -4px rgba(50,50,50, 0.2),0px 11px 15px 1px rgba(50,50,50, 0.14),0px 4px 20px 3px rgba(50,50,50, 0.12)",
        "0px 7px 8px -4px rgba(50,50,50, 0.2),0px 12px 17px 2px rgba(50,50,50, 0.14),0px 5px 22px 4px rgba(50,50,50, 0.12)",
        "0px 7px 8px -4px rgba(50,50,50, 0.2),0px 13px 19px 2px rgba(50,50,50, 0.14),0px 5px 24px 4px rgba(50,50,50, 0.12)",
        "0px 7px 9px -4px rgba(50,50,50, 0.2),0px 14px 21px 2px rgba(50,50,50, 0.14),0px 5px 26px 4px rgba(50,50,50, 0.12)",
        "0px 8px 9px -5px rgba(50,50,50, 0.2),0px 15px 22px 2px rgba(50,50,50, 0.14),0px 6px 28px 5px rgba(50,50,50, 0.12)",
        "0px 8px 10px -5px rgba(50,50,50, 0.2),0px 16px 24px 2px rgba(50,50,50, 0.14),0px 6px 30px 5px rgba(50,50,50, 0.12)",
        "0px 8px 11px -5px rgba(50,50,50, 0.2),0px 17px 26px 2px rgba(50,50,50, 0.14),0px 6px 32px 5px rgba(50,50,50, 0.12)",
        "0px 9px 11px -5px rgba(50,50,50, 0.2),0px 18px 28px 2px rgba(50,50,50, 0.14),0px 7px 34px 6px rgba(50,50,50, 0.12)",
        "0px 9px 12px -6px rgba(50,50,50, 0.2),0px 19px 29px 2px rgba(50,50,50, 0.14),0px 7px 36px 6px rgba(50,50,50, 0.12)",
        "0px 10px 13px -6px rgba(50,50,50, 0.2),0px 20px 31px 3px rgba(50,50,50, 0.14),0px 8px 38px 7px rgba(50,50,50, 0.12)",
        "0px 10px 13px -6px rgba(50,50,50, 0.2),0px 21px 33px 3px rgba(50,50,50, 0.14),0px 8px 40px 7px rgba(50,50,50, 0.12)",
        "0px 10px 14px -6px rgba(50,50,50, 0.2),0px 22px 35px 3px rgba(50,50,50, 0.14),0px 8px 42px 7px rgba(50,50,50, 0.12)",
        "0px 11px 14px -7px rgba(50,50,50, 0.2),0px 23px 36px 3px rgba(50,50,50, 0.14),0px 9px 44px 8px rgba(50,50,50, 0.12)",
        "0px 11px 15px -7px rgba(50,50,50, 0.2),0px 24px 38px 3px rgba(850,50,50 0.14),0px 9px 46px 8px rgba(50,50,50, 0.12)",
      ]
      : [
        "none",
        "0px 1px 3px 0px rgba(80,80,80, 0.2),0px 1px 1px 0px rgba(80,80,80, 0.14),0px 2px 1px -1px rgba(80,80,80, 0.12)",
        "0px 1px 5px 0px rgba(80,80,80, 0.2),0px 2px 2px 0px rgba(80,80,80, 0.14),0px 3px 1px -2px rgba(80,80,80, 0.12)",
        "0px 1px 8px 0px rgba(80,80,80, 0.2),0px 3px 4px 0px rgba(80,80,80, 0.14),0px 3px 3px -2px rgba(80,80,80, 0.12)",
        "0px 2px 4px -1px rgba(80,80,80, 0.2),0px 4px 5px 0px rgba(80,80,80, 0.14),0px 1px 10px 0px rgba(80,80,80, 0.12)",
        "0px 3px 5px -1px rgba(80,80,80, 0.2),0px 5px 8px 0px rgba(80,80,80, 0.14),0px 1px 14px 0px rgba(80,80,80, 0.12)",
        "0px 3px 5px -1px rgba(80,80,80, 0.2),0px 6px 10px 0px rgba(80,80,80, 0.14),0px 1px 18px 0px rgba(80,80,80, 0.12)",
        "0px 4px 5px -2px rgba(80,80,80, 0.2),0px 7px 10px 1px rgba(80,80,80, 0.14),0px 2px 16px 1px rgba(80,80,80, 0.12)",
        "0px 5px 5px -3px rgba(80,80,80, 0.2),0px 8px 10px 1px rgba(80,80,80, 0.14),0px 3px 14px 2px rgba(80,80,80, 0.12)",
        "0px 5px 6px -3px rgba(80,80,80, 0.2),0px 9px 12px 1px rgba(80,80,80, 0.14),0px 3px 16px 2px rgba(80,80,80, 0.12)",
        "0px 6px 6px -3px rgba(80,80,80, 0.2),0px 10px 14px 1px rgba(80,80,80, 0.14),0px 4px 18px 3px rgba(80,80,80, 0.12)",
        "0px 6px 7px -4px rgba(80,80,80, 0.2),0px 11px 15px 1px rgba(80,80,80, 0.14),0px 4px 20px 3px rgba(80,80,80, 0.12)",
        "0px 7px 8px -4px rgba(80,80,80, 0.2),0px 12px 17px 2px rgba(80,80,80, 0.14),0px 5px 22px 4px rgba(80,80,80, 0.12)",
        "0px 7px 8px -4px rgba(80,80,80, 0.2),0px 13px 19px 2px rgba(80,80,80, 0.14),0px 5px 24px 4px rgba(80,80,80, 0.12)",
        "0px 7px 9px -4px rgba(80,80,80, 0.2),0px 14px 21px 2px rgba(80,80,80, 0.14),0px 5px 26px 4px rgba(80,80,80, 0.12)",
        "0px 8px 9px -5px rgba(80,80,80, 0.2),0px 15px 22px 2px rgba(80,80,80, 0.14),0px 6px 28px 5px rgba(80,80,80, 0.12)",
        "0px 8px 10px -5px rgba(80,80,80, 0.2),0px 16px 24px 2px rgba(80,80,80, 0.14),0px 6px 30px 5px rgba(80,80,80, 0.12)",
        "0px 8px 11px -5px rgba(80,80,80, 0.2),0px 17px 26px 2px rgba(80,80,80, 0.14),0px 6px 32px 5px rgba(80,80,80, 0.12)",
        "0px 9px 11px -5px rgba(80,80,80, 0.2),0px 18px 28px 2px rgba(80,80,80, 0.14),0px 7px 34px 6px rgba(80,80,80, 0.12)",
        "0px 9px 12px -6px rgba(80,80,80, 0.2),0px 19px 29px 2px rgba(80,80,80, 0.14),0px 7px 36px 6px rgba(80,80,80, 0.12)",
        "0px 10px 13px -6px rgba(80,80,80, 0.2),0px 20px 31px 3px rgba(80,80,80, 0.14),0px 8px 38px 7px rgba(80,80,80, 0.12)",
        "0px 10px 13px -6px rgba(80,80,80, 0.2),0px 21px 33px 3px rgba(80,80,80, 0.14),0px 8px 40px 7px rgba(80,80,80, 0.12)",
        "0px 10px 14px -6px rgba(80,80,80, 0.2),0px 22px 35px 3px rgba(80,80,80, 0.14),0px 8px 42px 7px rgba(80,80,80, 0.12)",
        "0px 11px 14px -7px rgba(80,80,80, 0.2),0px 23px 36px 3px rgba(80,80,80, 0.14),0px 9px 44px 8px rgba(80,80,80, 0.12)",
        "0px 11px 15px -7px rgba(80,80,80, 0.2),0px 24px 38px 3px rgba(80,80,80, 0.14),0px 9px 46px 8px rgba(80,80,80, 0.12)",
      ],
  overrides: {
    MuiTable: {
      root: {
        margin: "12px 0 0 !important",
      },
      tableRoot: {
        marginBottom: "0 !important",
      },
    },
    MUIDataTable: {
      tableRoot: {
        marginBottom: "0 !important",
        marginTop: "0 !important",
        borderRadius: "6px",
        overflow: "hidden",
      },
      responsiveBase: {
        overflow: "display",
      },
    },
    MUIDataTableFooter: {
      root: {
        margin: "0 !important",
      },
    },
    MuiTableRow: {
      root: {
        borderBottom: "none !important",
        "&:hover": {
          backgroundColor: "#FAFAFA !important",
        },
      },
      footer: {
        "& td": {
          border: "none",
        },
      },
    },
    MUIDataTableHeadCell: {
      root: {
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "normal",
        background: mode === "dark" ? "#4C4C4C" : "white !important",
        color: "#000",
        borderBottom: "1px solid #E9E7FD !important",
        whiteSpace: "nowrap",
        textTransform: "uppercase",
        zIndex: "1 !important",
      },
      sortAction: {
        alignItems: "center",
        flexDirection: "row-reverse",
      },
    },
    MUIDataTableBodyRow: {
      root: {
        background: "rgba(255, 255, 255, 0.003)",
        "& .MuiTableCell-root": {
          borderBottom: "1px solid #E9E7FD !important",
          color: "#000",
          fontSize: "14px",
        },
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: "none !important",
        padding: "18px 20px !important",
        textWrap: "nowrap",
      },
      head: {
        fontWeight: 600,
      },
      body: {
        fontWeight: 400,
        fontSize: 15,
      },
    },
    MuiPopover: {
      root: {
        "& > div:first-of-type": {
          backgroundColor: "#0006 !important",
        },
      },
    },
    MUIDataTableFilter: {
      root: {
        position: "fixed",
        top: "50%",
        left: "50%",
        width: "100%",
        maxWidth: "400px",
        maxHeight: "500px",
        transform: "translate(-50%, -50%)",
        borderRadius: "0.5rem",
        zIndex: 100,
        background: mode === "dark" ? "rgba(0, 0, 0, 1)" : "#fff",
        boxShadow: "inset 0px -0.980695px 0px rgba(0, 0, 0, 0.12)",
        border: "1px solid #ccc",
        overflow: "hidden",
        padding: "10px !important",
        "& > ul": {
          overflow: "auto",
          maxHeight: "360px",
        },
        "& > *": {
          margin: "0 !important",
          padding: 10,
          "&:first-child": {
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            display: "flex",
            flexDirection: "column",
            "& > div": {
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
              "& p": {
                fontSize: "1rem",
                textTransform: "uppercase",
                color: "#1976D2",
              },
              "& button": {
                position: "absolute",
                bottom: 18,
                left: 135,
                boxShadow: "none",
                background: "none",
                color: "#1976D2 !important",
                zIndex: 5,
              },
            },
          },
          "&:last-child": {
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
            padding: "10px 15px",
            marginTop: "10px !important",
            width: "100%",
            background: "#fff",
          },
        },
        "& .MuiButtonBase-root": {
          background: themePalette(color, mode).palette.primary.main,
          boxShadow:
            "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
          borderRadius: "100px",
          color: "#fff",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "14px",
          textTransform: "Capitalize",
        },
        "& .MuiGridListTile-tile": {
          margin: "0 !important",
        },
      },
    },
    MUIDataTableFilterHeader: {
      background: "#000",
    },
    MuiGridListTile: {
      root: {
        padding: "0 5px !important",
        width: "100% !important",
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: mode === "dark" ? "#ffffff12" : "#FFFFFF",
      },
      rounded: {
        borderRadius: 8,
      },
      elevation1: {
        boxShadow:
          mode === "dark"
            ? "0px 1px 3px 0px rgba(64, 64, 64, 1), 0px 1px 1px 0px rgba(42, 42, 42, 1), 0px 2px 1px -1px rgba(20, 20, 20, 1)"
            : "0px 1px 3px 0px rgba(142, 142, 142, 0.2), 0px 1px 1px 0px rgba(243, 243, 243, 0.14), 0px 2px 1px -1px rgba(204, 204, 204, 0.12)",
      },
      elevation4: {
        boxShadow:
          mode === "dark"
            ? "0px 2px 4px -1px rgba(64, 64, 64, 0.46), 0px 4px 5px 0px rgba(42, 42, 42, 0.32), 0px 1px 10px 0px rgba(20, 20, 20, 0.12)"
            : "0px 2px 4px -1px rgba(142, 142, 142, 0.2), 0px 4px 5px 0px rgba(243, 243, 243, 0.14), 0px 1px 10px 0px rgba(204, 204, 204, 0.12)",
      },
    },
    MuiButtonGroup: {
      contained: {
        boxShadow: "none",
      },
      root: {
        borderRadius: 20,
        fontWeight: 600,
      },
      sizeSmall: {
        padding: "7px 12px",
      },
    },
    MuiIconButton: {
      root: {
        "&:hover": {
          background:
            mode === "dark" ? "#ffffff12 !important" : "#cfcfcf30 !important",
        },
      },
    },
    MuiFab: {
      root: {
        "&:hover": {
          background:
            mode === "dark" ? "#ffffff12 !important" : "#6d00dc !important",
        },
      },
    },
    MuiButton: {
      root: {
        fontWeight: 600,
        whiteSpace: "nowrap",
        textTransform: "none",
        boxShadow: "none",
        borderRadius: 8,
        padding: "12px 20px",
        fontSize: 15,
        fontStyle: "normal",
        minWidth: 94,
        maxHeight: 48,
      },
      outlined: {
        padding: "11px 20px",
        color: "black",
        border: "2px solid #EFEFEF",
        background: "#FCFCFC",
      },
      sizeSmall: {
        padding: "7px 12px",
      },
    },
    MuiTypography: {
      button: {
        fontWeight: 600,
      },
    },
    MuiInput: {
      root: {
        border:
          mode === "dark"
            ? "1px solid rgba(255,255,255,0.32)"
            : "1px solid rgba(0,0,0,0.32)",
        borderRadius: 8,
        alignItems: "center",
        transition: "border 0.3s ease",
      },
      underline: {
        "&:after": {
          height: "calc(100% + 1px)",
          borderRadius: 8,
          bottom: -1,
          boxShadow: `0 0 1px ${
            themePalette(color, mode).palette.primary.main
          }`,
        },
        "&:before": {
          display: "none",
        },
      },
      input: {
        padding: 10,
        fontSize: 14,
      },
      multiline: {
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 24,
      },
    },
    MuiInputLabel: {
      formControl: {
        top: 12,
        left: 10,
        transform: "translate(0, 22px) scale(1)",
      },
      shrink: {
        transform: "translate(0, 13px) scale(0.7)",
        zIndex: 1,
      },
      filled: {
        transform: "translate(2px, 6px) scale(1)",
        "&$shrink": {
          transform: "translate(0px, -6px) scale(0.75)",
        },
      },
      outlined: {
        transform: "translate(2px, 6px) scale(1)",
        "&$shrink": {
          transform: "translate(4px, -16px) scale(0.75)",
        },
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: 16,
        textTransform: "capitalize",
      },
    },
    MuiFormHelperText: {
      root: {
        paddingLeft: 5,
      },
    },
    MuiSelect: {
      root: {
        borderRadius: 12,
      },
      icon: {
        top: "calc(50% - 10px)",
        right: 0,
        "& path": {
          fill: "#8E8E8E",
        },
      },
      select: {
        "&:focus": {
          backgroundColor: "transparent",
        },
      },
    },
    MuiInputAdornment: {
      root: {
        "& button": {
          width: 32,
          height: 32,
          padding: 0,
        },
        "& p": {
          lineHeight: "16px",
          fontSize: 14,
          fontWeight: 500,
          color: "#9A9FA5",
        },
      },
      positionStart: {
        marginLeft: 0,
        marginRight: 0,
      },
      positionEnd: {
        marginRight: 0,
        marginLeft: 0,
      },
    },
    MuiToolbar: {
      root: {
        borderRadius: 8,
      },
    },
    MuiListItem: {
      root: {
        whiteSpace: "nowrap",
        "&.Mui-selected": {
          backgroundColor: "transparent",
          color: "black",
        },
      },
      gutters: {
        paddingLeft: 12,
        paddingRight: 12,
      },
    },
    MuiListItemText: {
      root: {
        whiteSpace: "nowrap",
      },
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 16,
      },
      bar: {
        borderRadius: 16,
      },
      colorPrimary: {
        backgroundColor: mode === "dark" ? "#616161" : "#ededed",
      },
    },
    MuiTablePagination: {
      root: {
        "&::-webkit-scrollbar": {
          height: "2px !important",
        },
      },
      input: {
        marginRight: 32,
        marginLeft: 8,
        background: "transparent",
      },
      selectRoot: {
        marginLeft: 0,
        marginRight: 0,
      },
      select: {
        paddingRight: 24,
        background: "transparent",
      },
      selectIcon: {
        top: 4,
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        borderRadius: 0,
        boxShadow: "inset 0 -30px 120px -30px rgba(0, 0, 0, 0.3)",
      },
    },
    MuiPickersClock: {
      clock: {
        backgroundColor: "none",
        border: `1px solid ${themePalette(color, mode).palette.primary.main}`,
      },
    },
    MuiPickersClockPointer: {
      thumb: {
        boxShadow: `0 1px 10px 0px ${
          themePalette(color, mode).palette.primary.main
        }`,
      },
    },
    MuiPickerDTTabs: {
      tabs: {
        backgroundColor: "transparent",
        color: themePalette(color, mode).palette.primary.main,
      },
    },
    MuiExpansionPanel: {
      root: {
        "&:first-child": {
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
        },
        "&:last-child": {
          borderBottomLeftRadius: 16,
          borderBottomRightRadius: 16,
        },
        "&$expanded": {
          borderRadius: 16,
          boxShadow: `0px 0px 0px 1px ${
            themePalette(color, mode).palette.primary.main
          }`,
          "& + div": {
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
          },
        },
      },
    },
    MuiDialog: {
      paper: {
        "& form": {
          paddingBottom: 20,
        },
      },
    },
    MuiDialogTitle: {
      root: {
        position: "relative",
        marginBottom: 32,
        "&:after": {
          content: "' '",
          position: "absolute",
          width: 60,
          height: 4,
          background: themePalette(color, mode).palette.primary.main,
          bottom: 0,
          left: 26,
        },
        "& h2": {
          color:
            mode === "dark"
              ? themePalette(color, mode).palette.primary.light
              : themePalette(color, mode).palette.primary.dark,
        },
      },
    },
    MuiSnackbarContent: {
      root: {
        "@media (min-width: 960px)": {
          borderRadius: 8,
        },
      },
    },
    MuiAppBar: {
      root: {
        boxShadow: "none",
      },
      colorPrimary: {
        backgroundColor:
          mode === "dark"
            ? themePalette(color, mode).palette.primary.dark
            : themePalette(color, mode).palette.primary.main,
      },
    },
    MuiTabs: {
      root: {
        borderRadius: 10,
      },
      indicator: {
        backgroundColor: themePalette(color, mode).palette.primary.main,
        borderRadius: "10px 10px 0 0",
        height: 2,
      },
    },
    MuiToggleButtonGroup: {
      root: {
        overflow: "hidden",
        borderRadius: 8,
        boxShadow: "none",
        border: `1px solid ${themePalette(color, mode).palette.secondary.main}`,
      },
    },
    MuiToggleButton: {
      root: {
        height: 32,
        boxShadow: "none !important",
        "&$selected": {
          color: themePalette(color, mode).palette.secondary.main,
          backgroundColor: themePalette(color, mode).palette.secondary.light,
        },
      },
    },
    MUIDataTableToolbarSelect: {
      root: {
        flex: "0 0 auto",
        minHeight: 64,
        boxShadow: "none",
        backgroundColor:
          mode === "dark"
            ? themePalette(color, mode).palette.secondary.dark
            : themePalette(color, mode).palette.secondary.light,
      },
      title: {
        padding: direction === "rtl" ? "0 26px 0 0" : "0 0 0 26px",
      },
      deleteIcon: {
        color: mode === "dark" ? "#FFF" : "#000",
      },
    },
    MuiChip: {
      deleteIcon: {
        margin: direction === "rtl" ? "0 -8px 0 4px" : "0 4px 0 -8px",
      },
    },
    MuiSwitch: {
      root: {
        direction: "ltr",
      },
    },
    MuiCheckbox: {
      root: {
        color: "#8E8E8E66",
      },
    },
    MuiInputBase: {
      root: {
        borderRadius: "8px !important",
        background: "#f8f8f8",
        border: "1px solid #D4D4D4",
        "&$multiline": {
          padding: "0px !important",
        },
      },
      input: {
        flex: 1,
        height: "21px",
        fontSize: 14,
        fontWeight: 500,
        color: "#1A1D1F",
        border: "2px solid transparent",
        "&::placeholder": {
          fontSize: 14,
          fontWeight: 500,
          color: "#8E8E8E",
          opacity: 1,
        },
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: "12px !important",
      },
      notchedOutline: {
        border: "2px solid transparent !important",
      },
      adornedEnd: {
        paddingRight: 12,
      },
      adornedStart: {
        paddingLeft: 12,
      },
    },
    MuiTreeItem: {
      root: {
        "& > div": {
          marginBottom: "10px !important",
        },
      },
    },
    MUIDataTableToolbar: {
      titleText: {
        "&::first-letter": {
          textTransform: "capitalize",
        },
      },
    },
  },
});

export default applicationTheme;
