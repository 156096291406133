import axios from "axios";
import memoizeOne from "memoize-one/dist/memoize-one";
import { getCookie, removeCookie, setCookie } from "containers/Pages/Chat/utils/cookiesHelper";
import urlUtils, { paths, save360Cookie } from "./url";

const setUserToken = (userToken) => {
  axios.defaults.headers.Authorization = `Bearer ${userToken}`;
  save360Cookie(userToken);
};

export function loadUser() {
  const token = getCookie("X-User-Token");
  setUserToken(token);
  return {
    token,
    info: {},
    loggedIn: Boolean(token),
  };
}

export function saveUser(token, rememberMe) {
  if (!rememberMe) {
    return document.cookie = `X-User-Token=${token};path=/;`;
  }

  localStorage.setItem("isGuided", true);
  setCookie("X-User-Token", token, 1);
  setCookie("X-Remember-Me", rememberMe, 1);
}

export async function login(email, password, rememberMe, notification_token) {
  const response = await axios.post(urlUtils.getProperApiUrl("yeshtery/token"), {
    email,
    password,
    isEmployee: true,
    remember_me: rememberMe,
    notification_token
  });
  setUserToken(response.data.refresh);
  return response.data;
}

export async function logout() {
  const { data } = await axios.post(urlUtils.getProperApiUrl("user/logout"));
  return data;
}

export function removeToken() {
  delete axios.defaults.headers.Authorization;
  delete axios.defaults.headers["User-Token"];
  removeCookie("X-User-Token");
  removeCookie("X-Remember-Me");
}

export function removeChatJwtToken() {
  removeCookie("chatJwtToken");
}

export async function recover(token, password) {
  const { data } = await axios.post(urlUtils.getProperApiUrl("/user/recover"), {
    employee: true,
    token,
    password,
  });
  return data;
}

export const roles = Object.freeze({
  NASNAV_ADMIN: {
    id: "NASNAV_ADMIN",
    name: "Nasnav Admin",
    requireShop: false,
  },
  ORGANIZATION_ADMIN: {
    id: "ORGANIZATION_ADMIN",
    name: "Organization Admin",
    requireShop: false,
  },
  ORGANIZATION_MANAGER: {
    id: "ORGANIZATION_MANAGER",
    name: "Organization Manager",
    requireShop: false,
  },
  ORGANIZATION_EMPLOYEE: {
    id: "ORGANIZATION_EMPLOYEE",
    name: "Organization Employee",
    requireShop: false,
  },
  // STORE_ADMIN: { id: "STORE_ADMIN", name: "Store Admin", requireShop: true },
  STORE_MANAGER: {
    id: "STORE_MANAGER",
    name: "Store Manager",
    requireShop: true,
  },
  STORE_EMPLOYEE: {
    id: "STORE_EMPLOYEE",
    name: "Store Employee",
    requireShop: true,
  },
});

export const rolesHierarchy = {
  NASNAV_ADMIN: [
    roles.NASNAV_ADMIN,
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
    roles.ORGANIZATION_EMPLOYEE,
    // can't create store roles as he can't list stores
    // roles.STORE_ADMIN,
    // roles.STORE_MANAGER,
    // roles.STORE_EMPLOYEE,
  ],
  ORGANIZATION_ADMIN: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
    roles.ORGANIZATION_EMPLOYEE,
    // roles.STORE_ADMIN,
    roles.STORE_MANAGER,
    roles.STORE_EMPLOYEE,
  ],
  // STORE_ADMIN: [roles.STORE_ADMIN, roles.STORE_MANAGER, roles.STORE_EMPLOYEE],
};

export const permissions = Object.freeze({
  [paths.dashboardPage]: [
    roles.NASNAV_ADMIN,
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
    roles.ORGANIZATION_EMPLOYEE,
    // roles.STORE_ADMIN,
    roles.STORE_MANAGER,
    roles.STORE_EMPLOYEE,
  ],
  [paths.organization]: [roles.NASNAV_ADMIN],
  [paths.UploadOrganizationImages]: [roles.ORGANIZATION_ADMIN, roles.ORGANIZATION_MANAGER],
  [paths.addBranch]: [roles.ORGANIZATION_ADMIN],
  [paths.allBranches]: [roles.ORGANIZATION_ADMIN],
  [paths.updateBranch]: [roles.ORGANIZATION_ADMIN],
  [paths.customersReviews]: [roles.ORGANIZATION_ADMIN],
  [paths.manageOrganizations]: [roles.NASNAV_ADMIN],
  [paths.updateOrganization]: [roles.NASNAV_ADMIN],
  [paths.manageDomains]: [roles.NASNAV_ADMIN],
  [paths.createCategory]: [roles.NASNAV_ADMIN],
  [paths.updateCategory]: [roles.NASNAV_ADMIN],
  [paths.categories]: [roles.NASNAV_ADMIN],
  [paths.themesClasses]: [roles.NASNAV_ADMIN],
  [paths.createTheme]: [roles.NASNAV_ADMIN],
  [paths.themes]: [roles.NASNAV_ADMIN],
  [paths.organizationsClasses]: [roles.NASNAV_ADMIN],
  [paths.createBrand]: [roles.ORGANIZATION_ADMIN],
  [paths.brands]: [roles.ORGANIZATION_ADMIN],
  [paths.createCollection]: [roles.ORGANIZATION_ADMIN],
  [paths.collections]: [roles.ORGANIZATION_ADMIN],
  [paths.createUser]: [
    roles.NASNAV_ADMIN,
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
    // roles.STORE_ADMIN,
  ],
  [paths.updateUser]: [
    roles.NASNAV_ADMIN,
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
    // roles.STORE_ADMIN,
  ],
  [paths.employees]: [
    roles.NASNAV_ADMIN,
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
    // roles.STORE_ADMIN,
  ],
  [paths.customers]: [roles.NASNAV_ADMIN, roles.ORGANIZATION_ADMIN, roles.ORGANIZATION_MANAGER],
  [paths.subscribedUsers]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.manageOrders]: [roles.ORGANIZATION_MANAGER, roles.STORE_MANAGER],
  [paths.metaOrders]: [
    roles.NASNAV_ADMIN,
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
    roles.ORGANIZATION_EMPLOYEE,
    // roles.STORE_ADMIN,
    roles.STORE_MANAGER,
    roles.STORE_EMPLOYEE,
  ],
  [paths.returnRequests]: [roles.ORGANIZATION_MANAGER, roles.STORE_MANAGER],
  [paths.singleReturnRequest]: [roles.ORGANIZATION_MANAGER, roles.STORE_MANAGER],
  [paths.findReturnRequest]: [roles.ORGANIZATION_MANAGER, roles.STORE_MANAGER],
  [paths.createAttribute]: [roles.ORGANIZATION_ADMIN, roles.ORGANIZATION_MANAGER],
  [paths.manageAttributes]: [roles.ORGANIZATION_ADMIN, roles.ORGANIZATION_MANAGER],
  [paths.updateAttribute]: [roles.ORGANIZATION_ADMIN, roles.ORGANIZATION_MANAGER],
  [paths.createProduct]: [roles.ORGANIZATION_ADMIN],
  [paths.products]: [roles.ORGANIZATION_ADMIN],
  [paths.productsImages]: [roles.ORGANIZATION_ADMIN],
  [paths.createPromotion]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.reviews]: [roles.ORGANIZATION_ADMIN],
  [paths.listPromotions]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.updatePromotion]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.createShop]: [roles.ORGANIZATION_MANAGER],
  [paths.shops]: [roles.ORGANIZATION_MANAGER, roles.STORE_MANAGER],
  [paths.subAreas]: [roles.ORGANIZATION_ADMIN],
  [paths.createTag]: [roles.ORGANIZATION_ADMIN],
  [paths.tags]: [roles.ORGANIZATION_ADMIN],
  [paths.tagsTree]: [roles.ORGANIZATION_ADMIN],
  [paths.createTagsTree]: [roles.ORGANIZATION_ADMIN],
  [paths.organizationImage]: [roles.ORGANIZATION_ADMIN],
  [paths.organizationInfo]: [roles.ORGANIZATION_ADMIN],
  [paths.organizationThemes]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.organizationSiteMap]: [
    roles.NASNAV_ADMIN,
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
    roles.ORGANIZATION_EMPLOYEE,
    // roles.STORE_ADMIN,
    roles.STORE_MANAGER,
    roles.STORE_EMPLOYEE,
  ],
  [paths.videoChat]: [roles.NASNAV_ADMIN, roles.ORGANIZATION_ADMIN],
  [paths.updateVariant]: [roles.ORGANIZATION_ADMIN],
  // New route permissions
  [paths.scanQr]: [roles.ORGANIZATION_ADMIN, roles.ORGANIZATION_MANAGER],
  [paths.scanQrDetails]: [roles.ORGANIZATION_ADMIN, roles.ORGANIZATION_MANAGER],
  [paths.singleOwnerRequest]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.manageOwnersShopsRequests]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.hotTagsSearch]: [roles.ORGANIZATION_ADMIN, roles.ORGANIZATION_MANAGER],
  [paths.storeTagsSearch]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.manageAmbassadors]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.influencerDetails]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.influencerEventDetails]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.ambassadorsRequests]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.influencerRequestsDetails]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.customerDetails]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.chat]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.chatServicesWidget]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.chatSettings]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.events]: [roles.ORGANIZATION_ADMIN, roles.ORGANIZATION_MANAGER],
  [paths.createEvent]: [roles.ORGANIZATION_ADMIN, roles.ORGANIZATION_MANAGER],
  [paths.updateEvent]: [roles.ORGANIZATION_ADMIN, roles.ORGANIZATION_MANAGER],
  [paths.updateAskForEvent]: [roles.ORGANIZATION_ADMIN, roles.ORGANIZATION_MANAGER],
  [paths.allEvent]: [roles.ORGANIZATION_ADMIN, roles.ORGANIZATION_MANAGER],
  [paths.eventDetails]: [roles.ORGANIZATION_ADMIN, roles.ORGANIZATION_MANAGER],
  [paths.askForEvent]: [roles.ORGANIZATION_ADMIN, roles.ORGANIZATION_MANAGER],
  [paths.influencersEventsRequests]: [
    roles.NASNAV_ADMIN,
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
    roles.ORGANIZATION_EMPLOYEE,
    roles.STORE_MANAGER,
    roles.STORE_EMPLOYEE,
  ],
  [paths.influencerEventRequestDetails]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.createService]: [
    roles.NASNAV_ADMIN,
  ],
  [paths.manageService]: [
    roles.NASNAV_ADMIN,
  ],
  [paths.updateService]: [
    roles.NASNAV_ADMIN,
  ],
  [paths.createPackage]: [
    roles.NASNAV_ADMIN,
  ],
  [paths.managePackage]: [
    roles.NASNAV_ADMIN,
  ],
  [paths.updatePackage]: [
    roles.NASNAV_ADMIN,
  ],
  [paths.manageCalls]: [roles.NASNAV_ADMIN,
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
    roles.ORGANIZATION_EMPLOYEE,
    roles.STORE_MANAGER,
    roles.STORE_EMPLOYEE
  ],
  [paths.manageQueue]: [roles.NASNAV_ADMIN,
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
    roles.ORGANIZATION_EMPLOYEE,
    roles.STORE_MANAGER,
    roles.STORE_EMPLOYEE
  ],
  [paths.bookingCalls]: [roles.NASNAV_ADMIN, roles.ORGANIZATION_ADMIN],
  [paths.availability]: [
    roles.ORGANIZATION_EMPLOYEE,
    roles.STORE_EMPLOYEE,
  ],
  [paths.videoCallsReviews]: [roles.NASNAV_ADMIN, roles.ORGANIZATION_ADMIN],
  [paths.sharedItems]: [roles.ORGANIZATION_ADMIN, roles.ORGANIZATION_MANAGER],
  [paths.reviewsRequests]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.reviewRequestDetails]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.manageReceipts]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.receiptDetails]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.userProfile]: [
    roles.NASNAV_ADMIN,
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.addAdvertisement]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.updateAdvertisement]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.advertisements]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  // 360 stores
  [paths.createStores]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.allStores]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.updateStores]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  // points
  [paths.pointsConfiguration]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.createPointsConfiguration]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.updatePointsConfiguration]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.controlPoints]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.createControlPoints]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.updateControlPoint]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  // Shipping services
  [paths.addShippingService]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.allShippingServices]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.updateShippingService]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.myProfile]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
    roles.ORGANIZATION_EMPLOYEE,
    roles.STORE_MANAGER,
    roles.STORE_EMPLOYEE,
    roles.NASNAV_ADMIN,
  ],
  [paths.importProductsV2]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
    roles.STORE_MANAGER,
  ],
  [paths.importProductsImages]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
    roles.STORE_MANAGER,
  ],
  [paths.chatWidget]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  // Metaverse
  [paths.createNewEvent]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  // Compensation
  [paths.createCompensation]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.manageCompensation]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  // 3D Product
  [paths.create3DProduct]: [
    roles.NASNAV_ADMIN,
  ],
  [paths.manage3DProducts]: [
    roles.NASNAV_ADMIN,
  ],
  [paths.product3DDetails]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
    roles.NASNAV_ADMIN,
  ],
  [paths.edit3DProduct]: [
    roles.NASNAV_ADMIN,
  ],
  [paths.userCheckout]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
    roles.ORGANIZATION_EMPLOYEE,
    roles.STORE_EMPLOYEE,
  ],
  [paths.businessInformation]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.updateBrand]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.UpdateTag]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.updateProduct]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
  [paths.singleOrder]: [
    roles.ORGANIZATION_ADMIN,
    roles.ORGANIZATION_MANAGER,
  ],
});

export const checkPermissions = memoizeOne((url, userRoles) => {
  const [, ...urlParts] = url.split("/");
  let currentUrl = url.endsWith("/") && url.length > 1
    ? url.substring(0, url.length - 1)
    : url;
  let currentPermissions = null;
  for (let i = urlParts.length; i > -1 && !currentPermissions; i -= 1) {
    const currentPart = urlParts[i];
    if (currentPart) {
      currentUrl = currentUrl.substring(
        0,
        currentUrl.length - currentPart.length - 1
      );
    }
    currentPermissions = permissions[currentUrl]?.map?.(({ id }) => id);
  }
  return userRoles.intersect(currentPermissions || []).size > 0 ?? false;
});

export const canSelectShop = memoizeOne((roleIds) =>
  roleIds.some(
    (roleId) =>
      roleId === roles.ORGANIZATION_ADMIN.id
            || roleId === roles.ORGANIZATION_MANAGER.id
  )
);
