import React from "react";

import AdminUserIcon from "api/icons/DashboardIcons/AdminUserIcon";
import BankIcon from "api/icons/DashboardIcons/BankIcon";
import CategoriesIcon from "api/icons/DashboardIcons/CategoriesIcon";
import ChatIcon from "api/icons/DashboardIcons/ChatIcon";
import CustomersIcon from "api/icons/DashboardIcons/CustomersIcon";
import DashboardIcon from "api/icons/DashboardIcons/DashboardIcon";
import InfluencerIcon from "api/icons/DashboardIcons/InfluencerIcon";
import MetaVerseIcon from "api/icons/DashboardIcons/MetaVerseIcon";
import OrdersIcon from "api/icons/DashboardIcons/OrdersIcon";
import PointsIcon from "api/icons/DashboardIcons/PointsIcon";
import ProductsBoxIcon from "api/icons/DashboardIcons/ProductsBoxIcon";
import PromotionIcon from "api/icons/DashboardIcons/PromotionIcon";
import ScanQrIcon from "api/icons/DashboardIcons/ScanQrIcon";
import SharedIcon from "api/icons/DashboardIcons/SharedIcon";
import Stores360Icon from "api/icons/DashboardIcons/Stores360Icon";
import TagsIcon from "api/icons/DashboardIcons/TagsIcon";
import VideoCallIcon from "api/icons/DashboardIcons/VideoCallIcon";
import AttributesIcon from "api/icons/MainSideBarIcons/AttributesIcon";
import OrdersSideBarIcon from "api/icons/MainSideBarIcons/OrdersSideBarIcon";
import UsersSideBarIcon from "api/icons/MainSideBarIcons/UsersSideBarIcon";
import { paths } from "utils/url";
import ShippingIcon from "api/icons/DashboardIcons/ShippingIcon";
import QueueIcon from "api/icons/DashboardIcons/QueueIcon";
import CompensationIcon from "api/icons/DashboardIcons/CompensationIcon";
import Add3DProductIcon from "api/icons/DashboardIcons/Add3DProductIcon";

export default [
  {
    groupKey: "main_menu",
    groupTitle: "Main Menu",
    mainIcon: <DashboardIcon />,
    groupList: [
      {
        key: "dashboard",
        name: "Dashboard",
        icon: <DashboardIcon />,
        link: paths.dashboardPage,
      },
      {
        key: "business profile",
        name: "business profile",
        icon: <BankIcon />,
        child: [
          {
            key: "create_organization",
            name: "Create Organization",
            link: paths.organization,
          },
          {
            key: "manage_organization",
            name: "Manage Organizations",
            link: paths.manageOrganizations,
          },
          {
            key: "manage_domains",
            name: "Manage Domains",
            link: paths.manageDomains,
          },
          {
            key: "profile",
            name: "profile",
            link: paths.businessInformation,
          },
          {
            key: "create_brand",
            name: "create Brand",
            link: paths.createBrand,
          },
          {
            key: "manage_brands",
            name: "manage Brands",
            link: paths.brands,
          },
          {
            key: "add_branch",
            name: "create branch",
            link: paths.addBranch,
          },
          {
            key: "manage_branches",
            name: "manage branches",
            link: paths.allBranches,
          },
          // {
          //   key: "organization_site_map",
          //   name: "Generate Site Map",
          //   link: paths.organizationSiteMap,
          // },
          //   {
          //     key: "organization_images",
          //     name: "upload images",
          //     link: paths.UploadOrganizationImages,
          //   },

        ],
      },
      {
        key: "category",
        name: "Categories",
        icon: <CategoriesIcon />,
        child: [
          {
            key: "create_category",
            name: "Crate Category",
            link: paths.createCategory,
          },
          {
            key: "update_category",
            name: "Manage Categories",
            link: paths.categories,
          },
        ],
      },
      {
        key: "admins",
        name: "Admins",
        icon: <AdminUserIcon />,
        child: [
          {
            key: "create_user",
            name: "Create Admin",
            link: paths.createUser,
          },
          {
            key: "manage_employees",
            name: "Manage admins",
            link: paths.employees,
          },
        //   {
        //     key: "subscribed_users",
        //     name: "Subscribed Users",
        //     link: paths.subscribedUsers,
        //   },
        ],
      },
      // {
      //   key: "owners shops requests",
      //   name: "owners shops requests",
      //   icon: <OwnerShopsIcon />,
      //   child: [
      //     {
      //       key: "manage requests",
      //       name: "manage requests",
      //       link: paths.manageOwnersShopsRequests,
      //     },
      //   ],
      // },
    ],
  },
  {
    groupKey: "Social",
    groupTitle: "Social",
    mainIcon: <UsersSideBarIcon />,
    groupList: [
      {
        key: "metaverse",
        name: "metaverse",
        icon: <MetaVerseIcon />,
        child: [
          {
            key: "create_an_event",
            name: "Create Event",
            link: paths.createNewEvent,
          },
          {
            key: "manage_events",
            name: "Manage Events",
            link: paths.events,
          },
          {
            key: "events_requests",
            name: "Events Request",
            link: paths.influencersEventsRequests,
          },
        ],
      },
      {
        key: "compensation",
        name: "compensation",
        icon: <CompensationIcon />,
        child: [
          {
            key: "create_compensation",
            name: "create Compensation",
            link: paths.createCompensation,
          },
          {
            key: "manage_compensation",
            name: "manage Compensation",
            link: paths.manageCompensation,
          },
        ],
      },
      {
        key: "for_you",
        name: "For You",
        icon: <SharedIcon />,
        child: [
          {
            key: "shared_items",
            name: "Shared Items",
            link: paths.sharedItems,
          },
          {
            key: "reviews_requests",
            name: "Reviews Requests",
            link: paths.reviewsRequests,
          },
        ],
      },
      {
        key: "Ambassadors",
        name: "Ambassadors",
        icon: <InfluencerIcon />,
        child: [
          {
            key: "Ambassadors_applied",
            name: "Ambassadors Applied",
            link: paths.ambassadorsRequests,
          },
          {
            key: "manage_ambassadors",
            name: "Manage ambassadors",
            link: paths.manageAmbassadors,
          },
        ],
      },
      // {
      //   key: "Search attributes",
      //   name: "Search attributes",
      //   icon: <SearchAttributesIcon />,
      //   child: [
      //     {
      //       key: "Hot search",
      //       name: "Hot search",
      //       link: paths.hotTagsSearch,
      //     },
      //     {
      //       key: "In the stores",
      //       name: "In the stores",
      //       link: paths.storeTagsSearch,
      //     },
      //   ],
      // },
    ],
  },
  {
    groupKey: "E-commerce",
    groupTitle: "E-commerce",
    mainIcon: <AttributesIcon />,
    groupList: [
      {
        key: "3D_Product",
        name: "3D Product",
        icon: <Add3DProductIcon />,
        child: [
          {
            key: "create_3D_product",
            name: "Create 3D Product",
            link: paths.create3DProduct,
          },
          {
            key: "manage_3D_products",
            name: "Manage 3D Products",
            link: paths.manage3DProducts,
          },
        ],
      },
      {
        key: "products",
        name: "Products",
        icon: <ProductsBoxIcon />,
        child: [
          // {
          //   key: "create_product_feature",
          //   name: "Add New Feature",
          //   link: paths.createProductFeature,
          // },
          // {
          //   key: "update_product_features",
          //   name: "Edit Product Features",
          //   link: paths.productFeatures,
          // },
          {
            key: "import_products",
            name: "Import Products",
            link: paths.importProductsV2,
          },
          {
            key: "import_products_images",
            name: "Import Products Images",
            link: paths.importProductsImages,
          },
          {
            key: "create_product",
            name: "create Product",
            link: paths.createProduct,
          },
          {
            key: "manage_products",
            name: "Manage Products",
            link: paths.products,
          },
          {
            key: "create_attribute",
            name: "create attribute",
            link: paths.createAttribute,
          },
          {
            key: "manage_attributes",
            name: "manage attributes",
            link: paths.manageAttributes,
          },
          // {
          //   key: "export_products",
          //   name: "Export Products",
          //   link: paths.exportProducts,
          // },
          // {
          //   key: "import_products",
          //   name: "Import Products",
          //   link: paths.importProducts,
          // },
          // {
          //   key: "products_images",
          //   name: "Import Products Images",
          //   link: paths.productsImages,
          // },
          // {
          //   key: "products_reviews",
          //   name: "Products Reviews",
          //   link: paths.reviews,
          // },
        ],
      },
      //   {
      //     key: "products_attributes",
      //     name: "products attributes",
      //     icon: <ProductsAttributesIcon />,
      //     child: [
      //       // {
      //       //   key: "color",
      //       //   name: "color",
      //       //   link: "paths.productsAttributes",
      //       // },
      //       // {
      //       //   key: "size",
      //       //   name: "size",
      //       //   link: "paths.productsAttributess",
      //       // },
      //       {
      //         key: "all_features",
      //         name: "All features",
      //         link: paths.productsFeatures,
      //       },
      //     ],
      //   },
      {
        key: "tags",
        name: "Tags",
        icon: <TagsIcon />,
        child: [
          {
            key: "create_tag",
            name: "create Tag",
            link: paths.createTag,
          },
          {
            key: "manage_tags",
            name: "Manage Tags",
            link: paths.tags,
          },
          {
            key: "tags_tree_preview",
            name: "Tags tree preview",
            link: paths.tagsTree,
          },
        ],
      },
      // {
      //   key: "online_stores",
      //   name: "online stores",
      //   icon: <GlobalWorldIcon />,
      //   child: [
      //     {
      //       key: "add_store",
      //       name: "Add store",
      //       // path
      //     },
      //     {
      //       key: "edit_stores",
      //       name: "edit stores",
      //       // path
      //     },
      //   ],
      // },
      //   {
      //     key: "organizations_branches",
      //     name: "branches",
      //     icon: <BuildingIcon />,
      //     child: [
      //       {
      //         key: "add_new_branch",
      //         name: "Add new branch",
      //         link: paths.addBranch,
      //       },
      //       {
      //         key: "all_branches",
      //         name: "All branches",
      //         link: paths.allBranches,
      //       },
      //       // {
      //       //   key: "reviews",
      //       //   name: "reviews",
      //       //   link: paths.customersReviews,
      //       // },
      //     ],
      //   },
      //   {
      //     key: "brands",
      //     name: "Brands",
      //     icon: <BrandShopsIcon />,
      //     child: [
      //       {
      //         key: "create_brand",
      //         name: "Add Brand",
      //         link: paths.createBrand,
      //       },
      //       {
      //         key: "update_brands",
      //         name: "All Brand",
      //         link: paths.brands,
      //       },
      //     ],
      //   },
      {
        key: "360_stores",
        name: "360° stores",
        icon: <Stores360Icon />,
        child: [
          {
            key: "create_360_stores",
            name: "Create 360° stores",
            link: paths.createStores,
          },
          {
            key: "manage_360_stores",
            name: "Manage 360° stores",
            link: paths.allStores,
          },
          // {
          //   key: "create_shop",
          //   name: "Add New Shop",
          //   link: paths.createShop,
          // },
          // {
          //   key: "update_shop",
          //   name: "Edit Shops",
          //   link: paths.shops,
          // },
          {
            key: "manage_areas",
            name: "Manage Areas",
            link: paths.subAreas,
          },
        ],
      },
      {
        key: "Loyalty",
        name: "Loyalty",
        icon: <PointsIcon />,
        child: [
          {
            key: "create_tier",
            name: "Create Tier",
            link: paths.createControlPoints,
          },
          {
            key: "manage_tiers",
            name: "Manage Tiers",
            link: paths.controlPoints,
          },
          {
            key: "create_configuration",
            name: "Create Configuration",
            link: paths.createPointsConfiguration,
          },
          {
            key: "manage_configurations",
            name: "Manage Configurations",
            link: paths.pointsConfiguration,
          },
        ],
      },
      {
        key: "promotions",
        name: "Promotions",
        icon: <PromotionIcon />,
        child: [
          {
            key: "create_promotion",
            name: "Create Promotion",
            link: paths.createPromotion,
          },
          {
            key: "manage_promotions",
            name: "Manage Promotions",
            link: paths.listPromotions,
          },
        ],
      },
      // {
      //   key: "collections",
      //   name: "Collections",
      //   icon: <CategoriesIcon />,
      //   child: [
      //     {
      //       key: "create_collection",
      //       name: "Add New Collection",
      //       link: paths.createCollection,
      //     },
      //     {
      //       key: "update_collection",
      //       name: "Edit Collections",
      //       link: paths.collections,
      //     },
      //   ],
      // },
      // {
      //   key: "coins",
      //   name: "Coins",
      //   icon: <CoinsIcon />,
      //   child: [
      //     {
      //       key: "configration",
      //       name: "configration",
      //       // path
      //     },
      //     {
      //       key: "control_coins",
      //       name: "control coins",
      //       // path
      //     },
      //   ],
      // },
      {
        key: "Packages",
        name: "Packages",
        icon: <ProductsBoxIcon />,
        child: [
          {
            key: "create_package",
            name: "Create Package",
            link: paths.createPackage,
          },
          {
            key: "manage_package",
            name: "Manage Package",
            link: paths.managePackage,
          },
        ],
      },
      {
        key: "Services",
        name: "Services",
        icon: <BankIcon />,
        child: [
          {
            key: "create_service",
            name: "Create Service",
            link: paths.createService,
          },
          {
            key: "manage_service",
            name: "Manage Service",
            link: paths.manageService,
          },
        ],
      },
      {
        key: "Scan_for_pick",
        name: "Scan qr code",
        icon: <ScanQrIcon />,
        link: paths.scanQr,
      },
    ],
  },
  // {
  //   groupKey: "Additional_featured",
  //   groupTitle: "Additional featured",
  //   mainIcon: <AdditionalFeaturedIcon />,
  //   groupList: [
  //     // {
  //     //   key: "video_chat",
  //     //   name: "Video Chat",
  //     //   icon: <VideoCallIcon />,
  //     //   child: [
  //     //     {
  //     //       key: "video_chats",
  //     //       name: "Video Chats",
  //     //       link: paths.videoChat,
  //     //     },
  //     //   ],
  //     // },
  //     // {
  //     //   key: "theme",
  //     //   name: "Theme",
  //     //   icon: <TagsIcon />,
  //     //   child: [
  //     //     {
  //     //       key: "organization_themes",
  //     //       name: "Edit theme settings",
  //     //       link: paths.organizationThemes,
  //     //     },
  //     //     {
  //     //       key: "organization_image",
  //     //       name: "Upload images",
  //     //       link: paths.organizationImage,
  //     //     },
  //     //     {
  //     //       key: "themes_classes",
  //     //       name: "Themes Classes",
  //     //       link: paths.themesClasses,
  //     //     },
  //     //     {
  //     //       key: "organizations_classes",
  //     //       name: "Organizations Classes",
  //     //       link: paths.organizationsClasses,
  //     //     },
  //     //     {
  //     //       key: "create_theme",
  //     //       name: "Create Theme",
  //     //       link: paths.createTheme,
  //     //     },
  //     //     {
  //     //       key: "update_themes",
  //     //       name: "Edit Themes",
  //     //       link: paths.themes,
  //     //     },
  //     //   ],
  //     // },
  //   ],
  // },
  {
    groupKey: "orders",
    groupTitle: "Orders",
    mainIcon: <OrdersSideBarIcon />,
    groupList: [
      {
        key: "orders",
        name: "Orders",
        icon: <OrdersIcon />,
        child: [
          {
            key: "manage_orders",
            name: "Manage Orders",
            link: paths.manageOrders,
          },
          {
            key: "return_orders",
            name: "return orders",
            link: paths.returnRequests,
          },
          // {
          //   key: "find_return_request",
          //   name: "Find Return Request",
          //   link: paths.findReturnRequest,
          // },
        ],
      },
      {
        key: "shipping_services",
        name: "Shipping",
        icon: <ShippingIcon />,
        child: [
          {
            key: "create_shipping_service",
            name: "Create Shipping",
            link: paths.addShippingService,
          },
          {
            key: "manage_shipping_service",
            name: "Manage Shipping",
            link: paths.allShippingServices,
          },
        ],
      },
      // {
      //   key: "receipts",
      //   name: "Receipts",
      //   icon: <ReceiptsIcon />,
      //   child: [
      //     {
      //       key: "manage_receipts",
      //       name: "Manage Receipts",
      //       link: paths.manageReceipts,
      //     },
      //   ],
      // },
    ],
  },
  {
    groupKey: "Customer_Support",
    groupTitle: "Customer Support",
    mainIcon: <ChatIcon />,
    groupList: [
      {
        key: "video_calls",
        name: "Video Calls",
        icon: <VideoCallIcon />,
        child: [
          {
            key: "manage_calls",
            name: "Manage Calls",
            link: paths.manageCalls,
          },
          {
            key: "booking_calls",
            name: "Booking Calls",
            link: paths.bookingCalls,
          },
          {
            key: "availability",
            name: "Availability",
            link: paths.availability,
          },
          // {
          //   key: "video_calls_reviews",
          //   name: "Reviews",
          //   link: paths.videoCallsReviews,
          // },
        ],
      },
      {
        key: "queue",
        name: "Queue",
        icon: <QueueIcon />,
        link: paths.manageQueue,
      },
      {
        key: "Chat_Services",
        name: "Chat Services",
        icon: <ChatIcon />,
        child: [
          {
            key: "chat",
            name: "Chat",
            link: paths.chat,
          },
          // {
          //   key: "chat_settings",
          //   name: "Chat settings",
          //   link: paths.chatSettings,
          // },
          // {
          //   key: "chat_services_widget",
          //   name: "Widget settings",
          //   link: paths.chatServicesWidget,
          // },
          {
            key: "chat_widget",
            name: "Chat Widget",
            link: paths.chatWidget,
          },
        ],
      },
      {
        key: "customers",
        name: "Customers",
        icon: <CustomersIcon />,
        link: paths.customers,
      },
    ],
  },
];
