const isYeshtery = process.env.REACT_APP_BUILD_YESHTERY === "true";

// For whoever doesn't have the .env file, this is the default value
const fallbackBackendUrl = isYeshtery
  ? "https://api-yeshtery.dev.meetusvr.com/v1"
  : "https://api.dev.meetusvr.com";
let baseUrl = fallbackBackendUrl;

const publicApiPrefix = isYeshtery ? "/yeshtery" : "/navbox";

/**
 * Debugging env variables
 */
localStorage.setItem("Env", JSON.stringify(process.env));

if (process.env.REACT_APP_RESTAPI) {
  baseUrl = process.env.REACT_APP_RESTAPI;
}

const filesStorageUrl =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BUILD_YESHTERY === "true"
      ? "https://api-yeshtery.dev.meetusvr.com/v1/files"
      : "https://api.dev.meetusvr.com/files"
    : "/files";

document.cookie = `backend_url=${baseUrl};path=/;`;

export const save360Cookie = (userToken) => {
  const pannellumCookie = {
    backend_api_url: baseUrl,
    files_api_url: filesStorageUrl,
    user_token: userToken,
  };
  document.cookie = `360=${JSON.stringify(pannellumCookie)}; path=/;`;
};

save360Cookie();

const addSlash = (url, changeCase = false) => {
  const newUrl = url ? (changeCase ? url.toLowerCase() : url) : "";
  return (
    (newUrl && newUrl.startsWith("/") ? "" : "/") + encodeURI(newUrl.trim())
  );
};

const getProperApiUrl = (url, isPublic = false) =>
  baseUrl + (isPublic ? publicApiPrefix : "") + addSlash(url);

const getProperImageUrl = (url) =>
  url && filesStorageUrl + addSlash(url, false);

const defaultPName = (name) =>
  name
    .replace(/[^a-zA-Z0-9]+/g, "-")
    .replace(/^-|-$/g, "")
    .toLowerCase();

/**
 * converts query parameters to an object
 * @param {string} search
 */
const toSearchParams = (queryObject) =>
  "?" +
  Object.entries(queryObject)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

const paths = {
  dashboardPage: "/",
  organization: "/create-update-organization",
  UploadOrganizationImages: "/upload-organization-images",
  manageOrganizations: "/manage-organizations",
  updateOrganization: "/update-organization/:orgId(\\d+)/:orgName?",
  manageDomains: "/manage-domains",
  createCategory: "/create-category",
  updateCategory: "/update-category/:categoryId(\\d+)/:categoryName?",
  createBrand: "/create-brand",
  createShop: "/create-shop",
  shopSections: "/shops/:shopId(\\d+)/:shopName/360-images",
  shop360Bulk: "/shops/:shopId(\\d+)/:shopName/360-bulk",
  shop360Editor: "/shops/:shopId(\\d+)/:shopName/360-editor",
  updateShop: "/shops/:shopId(\\d+)/:shopName?",
  shops: "/shops",
  subAreas: "/manage-areas",
  organizationImage: "/organization-image/:imageId?/:imageTitle?",
  organizationInfo: "/organization-info",
  createUser: "/create-admin",
  updateUser: "/manage-admins/:userId(\\d+)/:userName?",
  employees: "/manage-admins",
  customers: "/customers",
  subscribedUsers: "/subscribed-users",
  manageOrders: "/manage-orders",
  singleOrder: "/orders/:orderId(\\d+)/order-Details",
  metaOrders: "/meta-orders",
  singleMetaOrder: "/meta-orders/:orderId(\\d+)",
  findReturnRequest: "/find-return-request",
  returnRequests: "/return-orders",
  singleReturnRequest:
    "/return-orders/:returnOrderId(\\d+)/:MetaOrderId(\\d+)/return-order-details",
  createAttribute: "/create-attribute",
  manageAttributes: "/manage-attributes",
  updateAttribute: "/update-attribute/:attributeId(\\d+)/:attributeName?",
  createTag: "/create-tag",
  updateTag: "/manage-tags/:tagId(\\d+)/:tagName?",
  tagProducts: "/manage-tags/:tagId(\\d+)/:tagName/products",
  tags: "/manage-tags",
  createProduct: "/create-product",
  updateProduct: "/products/:productId(\\d+)/:productName?",
  relatedProducts: "/products/:productId(\\d+)/:productName/related",
  productVariants: "/products/:productId(\\d+)/:productName/variants",
  products: "/manage-products",
  createCollection: "/create-collection",
  updateCollection: "/collections/:collectionId(\\d+)/:collectionName?",
  collectionProducts:
    "/collections/:collectionId(\\d+)/:collectionName/products",
  relatedCollections:
    "/collections/:collectionId(\\d+)/:collectionName/related",
  collections: "/collections",
  updateBrand: "/brands/:brandId(\\d+)/:brandName?",
  brands: "/manage-brands",
  productsImages: "/products-images",
  themesClasses: "/themes-classes",
  createTheme: "/create-theme",
  themes: "/themes",
  UpdateTheme: "/themes/:themeId/:themeName?",
  organizationsClasses: "/organizations-classes",
  organizationThemes: "/organization-themes",
  organizationSiteMap: "/organization-site-map",
  createPromotion: "/create-promotion",
  updatePromotion: "/promotions/:promoId(\\d+)/:promoName?",
  listPromotions: "/manage-promotions",
  reviews: "/reviews",
  categories: "/manage-categories",
  videoChat: "/video-chat",
  updateVariant:
    "/products/:productId(\\d+)/:productName/variants/:variantId(\\d+)/:variantName",
  // New paths
  tagsTree: "/tags-tree-preview",
  createTagsTree: "/tags-tree",
  scanQr: "/scan-qr-code",
  scanQrDetails: "/scan-qr-code/:scanQrId(\\d+)",
  searchAttributes: "/search-attributes",
  hotTagsSearch: "/hot-search",
  storeTagsSearch: "/store-tags",
  manageOwnersShopsRequests: "/owners-shops-requests",
  manageOwnerShopRequest: "/owners-shops-requests/:requestId(\\d+)",
  singleOwnerRequest: "/owner-shop-requests/:orderId(\\d+)",
  customerDetails: "/customer-details/:id",
  chat: "/chat",
  chatServicesWidget: "/chat-services-widget",
  chatSettings: "/chat-settings",
  chatInfo: "/chat/:chatId/chat",
  // advertisements: "/advertisements",
  // addAdvertisement: "/add-advertisement",
  manageAmbassadors: "/manage-ambassadors",
  ambassadorsRequests: "/ambassadors-applied",
  influencerDetails: "/influencer-details/:influencerId(\\d+)/:influencerName",
  influencerEventDetails:
    "/influencer-details/:eventId(\\d+)/:influencerName/events/:eventName",
  influencerRequestsDetails:
    "/influencer-requests-details/:influencerId(\\d+)/:influencerName",
  createService: "/create-service",
  manageService: "/manage-service",
  updateService: "/update-service/:serviceId?/:serviceName?",
  createPackage: "/create-package",
  managePackage: "/manage-package",
  updatePackage: "/update-package/:packageId?/:packageName?",
  createEvent: "/create-event",
  allEvent: "/all-event/:eventId(\\d+)/:eventName",
  updateEvent: "/update-event/:eventId(\\d+)",
  updateAskForEvent: "/update-ask-for-event/:eventId(\\d+)",
  events: "/manage-events",
  eventDetails: "/events/:eventId(\\d+)/event-details",
  askForEvent: "/events/ask-for-event",
  influencersEventsRequests: "/events/influencers-requests",
  influencerEventRequestDetails: "/events/events-requests/:requestId(\\d+)",
  manageCalls: "/video-calls",
  manageQueue: "/manage-queue",
  bookingCalls: "/booking-calls",
  availability: "/video-calls/availability",
  videoCallsReviews: "/video-calls/reviews",
  sharedItems: "/shared-items",
  reviewsRequests: "/reviews-requests",
  reviewRequestDetails:
    "/review-request-details/:requestId(\\d+)/:requestName?",
  manageReceipts: "/receipts",
  receiptDetails: "/receipt-details/:receiptId(\\d+)/:receiptName?",
  userProfile: "/users/:userId(\\d+)/:userName?",
  // organizations’ branches
  addBranch: "/create-branch",
  allBranches: "/manage-branches",
  updateBranch: "/update-branch/:branchId(\\d+)/:branchName?",
  customersReviews: "/customers-reviews",
  addAdvertisement: "/add-advertisement",
  updateAdvertisement: "/update-advertisement/:advertisementId(\\d+)",
  advertisements: "/advertisements",
  // 360Stores
  createStores: "/create-360-stores",
  allStores: "/manage-360°-stores",
  updateStores: "/update-stores/:storeId(\\d+)/:storeTitle?/:storeName?",
  // points
  pointsConfiguration: "/manage-configurations",
  createPointsConfiguration: "/create-configuration",
  updatePointsConfiguration: "/update-configuration/:configId?/:configName?",
  controlPoints: "/manage-tiers",
  createControlPoints: "/create-tier",
  updateControlPoint: "/update-tier/:tierId?/:tierName?",
  // Shipping services
  addShippingService: "/create-shipping",
  allShippingServices: "/manage-shipping",
  updateShippingService: "/update-shipping-service/:serviceId/:serviceName?",
  myProfile: "/my-profile/:userId/:userName?/profile-page",
  importProductsV2: "/import-products",
  importProductsImages: "/import-products-images",
  chatWidget: "/chat-widget",
  // Metaverse
  createNewEvent: "/create-new-event",
  // compensation
  createCompensation: "/create-compensation",
  manageCompensation: "/manage-compensation",
  // 3D Product
  create3DProduct: "/create-3D-product",
  edit3DProduct: "/create-3D-product/:SKU_BARCODE?/:code?/:modelId?/:productName?",
  manage3DProducts: "/manage-3D-products",
  product3DDetails: "/3D-Product-Details/:SKU_BARCODE?/:code?/3D-Product-Details",
  userCheckout: "/user-checkout/:userId/scan-qr-code",
  businessInformation: "/business-information",
};

export { paths };

export default {
  getProperApiUrl,
  getProperImageUrl,
  toSearchParams,
  defaultPName,
};
