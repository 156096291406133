const styles = (theme) => ({
  sidebarGroupContainer: {
    position: "relative",
    width: "100%",
  },
  groupMenuWidth: {
    width: "100%",
    height: 75,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: 2,
    paddingTop: 4,
    paddingBottom: 4,
    "& p": {
      fontFamily: theme.typography.fontFamily,
      color: "#8B909A",
      textAlign: "center",
      fontSize: 10,
      fontWeight: 500,
    },
    "& svg": {
      "& path": {
        fill: "#8E8E8E",
      },
    },
  },
  menuActive: {
    position: "relative",
    width: "100%",
    "& svg path": {
      fill: theme.palette.uniqueColor,
    },
    "&:after": {
      content: "''",
      position: "absolute",
      top: 10,
      right: 0,
      width: 3,
      height: "calc(100% - 20px)",
      background: theme.palette.uniqueColor,
      borderRadius: "8px 0 0 8px",
    },
    "& p": {
      color: theme.palette.uniqueColor,
    },
  },
  sidebarGroupMenu: {
    width: 245,
    height: "calc(100% - 81px)",
    padding: 16,
    position: "fixed",
    bottom: 0,
    left: 80,
    zIndex: 9999,
    background: "#fff",
    boxShadow: "6px 4px 30px 0px rgba(0, 0, 0, 0.15)",
    color: "black",
    transition: "all 0.3s ease-in-out",
  },
  showSidebarGroupMenu: {
    opacity: 1,
    visibility: "visible",
  },
  hideSidebarGroupMenu: {
    visibility: "hidden",
    opacity: 0,
  },
  mainGroupTitle: {
    fontSize: 14,
    fontWeight: 600,
    textTransform: "uppercase",
    marginBottom: 12,
  },
  groupContainer: {
    position: "relative",
  },
  groupList: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    padding: 16,
    "&.collapsed": {
      padding: "0px 0px",
    },
  },
  groupTitle: {
    color: "black",
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: theme.typography.fontFamily,
    lineHeight: "150%",
    textTransform: "uppercase",
    marginBottom: 4,
    padding: 0,
    position: "static",
  },
  hide: {
    display: "none",
  },
  groupListItem: {
    textTransform: "capitalize",
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid transparent",
    color:
      theme.palette.type === "dark" ? "white" : theme.palette.primary.mainLight,
    padding: "8px",
    "&:hover": {
      borderRadius: 8,
      backgroundColor:
        theme.palette.type === "dark"
          ? theme.palette.borderDark
          : "#64749114",
    },
    "& .MuiTypography-body1": {
      whiteSpace: "break-spaces",
      fontSize: "14px",
      fontWeight: "500",
      color:
        theme.palette.type === "dark"
          ? "white"
          : theme.palette.primary.mainLight,
      fontFamily: theme.typography.fontFamily,
      lineHeight: "150%",
      textTransform: "capitalize",
    },
    "& .MuiList-root": {
      paddingLeft: 20,
    },
    "& svg": {
      flexShrink: 0,
    },
  },
  iconWithTextContainer: {
    display: "flex",
    gap: 12,
    alignItems: "center",
  },
  groupListItemIcon: {
    color: "transparent",
    minWidth: 0,
    "& svg path": {
      fill:
        theme.palette.type === "dark"
          ? "#fff"
          : theme.palette.primary.mainLight,
    },
  },
  iconWithColorActive: {
    "& svg path": {
      fill: theme.palette.type === "dark" ? "#fff" : theme.palette.uniqueColor,
    },
  },
  groupListItemText: {
    color: theme.palette.type === "dark" ? "white" : "black",
  },
  primary: {
    textWrap: "balance",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "150%",
    textTransform: "capitalize",
    fontFamily: theme.typography.fontFamily,
  },
  arrowColored: {
    "& path": {
      fill: theme.palette.uniqueColor,
    },
  },
  noList: {
    listStyle: "none",
  },
  child: {
    "& a": {
      paddingLeft: theme.spacing(6),
      fontSize: "30px",
    },
  },
  gapIcon: {
    gap: 12,
  },
  active: {
    position: "relative",
    "& .MuiTypography-body1": {
      // color: `${theme.palette.primary.main} !important`,
    },
    "& $groupListItemIcon svg path": {
      fill: theme.palette.type === "dark" ? "#fff" : theme.palette.uniqueColor,
    },
    "&:hover": {
      backgroundColor:
        theme.palette.type === "dark"
          ? theme.palette.borderDark
          : theme.palette.primary.light,
    },
  },
  badge: {
    height: "auto",
  },
  sidebarCollapsed: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 18,
    paddingBottom: 18,
  },
  listItemWithIconActive: {
    borderRadius: "8px",
    marginBottom: 8,
    background: "#E8E7EA",
    transition: "all 0.2s ease-in-out",
    border: "1px solid #FF5400",
    "&:hover": {
      background: "rgba(255, 255, 255, 0.40)",
    },
  },
  listItemActive: {
    borderRadius: "8px",
    background: "#E8E7EA",
    transition: "background 0.3s ease-in-out",
    border: "1px solid #CDCDCD",
    "&:hover": {
      background: "rgba(255, 255, 255, 0.40)",
    },
    "& span": {
      fontWeight: "600",
    }
  },
  noPaddingNav: {
    padding: 0,
  },
  paddingLeft: {
    paddingLeft: 36,
  },
  mainIconActive: {
    "& div": {
      "& svg path": {
        fill: theme.palette.uniqueColor,
      },
    },
  },
});

export default styles;
