import React from "react";
import MUIDataTable from "mui-datatables";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import SliderFilter from "./FiltersOptions/Slider";

const getOptionsByFilterType = (filterType, label, formatter) => {
  switch (filterType) {
    case "Slider":
      return SliderFilter(label, formatter);
    default:
      return { filterOptions: {}, customFilterListOptions: {} };
  }
};

const getformatValueOptions = (
  renderValue = (x) => x,
  tableData,
  columnName,
  columnIndex
) => ({
  customBodyRenderLite: (dataIndex) => {
    const row = tableData[dataIndex];
    const value = Array.isArray(row) ? row[columnIndex] : row[columnName];
    return renderValue(value, row, dataIndex);
  },
});

const mapColumns = (oldColumns, data = []) => {
  const columnsValues = {};
  oldColumns.forEach(({ name }) => {
    columnsValues[name] = new Set();
  });
  data.forEach((row) => {
    oldColumns.forEach(({ name }) => {
      columnsValues[name].add(row[name]);
    });
  });
  Object.keys(columnsValues).forEach((key) => {
    columnsValues[key] = Array.from(columnsValues[key]);
  });
  return oldColumns.map((columnMeta, columnIndex) => {
    const {
      name,
      label,
      options: {
        filterOptions: outerFilterOptions = {},
        filterType,
        renderValue,
        setCellProps,
        customFilterListOptions: outerCustomFilterListOptions = {},
      } = {},
    } = columnMeta;
    const optionsFromFilterType = getOptionsByFilterType(
      filterType,
      label,
      outerFilterOptions.renderValue || renderValue
    );
    const { display = optionsFromFilterType.filterOptions.display, names } = outerFilterOptions;
    const filterOptions = {};
    if (display) {
      filterOptions.display = (filterList, onChange, index, column) => {
        const outerOnChange = (value) => onChange(value, index, column);
        return display(
          names || columnsValues[name],
          filterList[index],
          outerOnChange
        );
      };
    }
    return {
      ...columnMeta,
      options: {
        ...columnMeta.options,
        ...optionsFromFilterType,
        ...getformatValueOptions(renderValue, data, name, columnIndex),
        filterOptions: {
          ...optionsFromFilterType.filterOptions,
          ...outerFilterOptions,
          ...filterOptions,
        },
        customFilterListOptions: {
          ...optionsFromFilterType.customFilterListOptions,
          ...outerCustomFilterListOptions,
        },
      },
    };
  });
};

const mapfilterListtoMap = (filterList, columns) =>
  filterList.reduce((map, filterValues, idx) => {
    const { name, options: { filter = true, filterType = "dropdown" } = {} } = columns[idx];
    const value = filterType === "dropdown" ? filterValues[0] : filterValues;
    // eslint-disable-next-line no-param-reassign
    if (filter && value && (!Array.isArray(value) || value.length)) map[name] = value;
    return map;
  }, {});

const mapOptions = (options, columns, data) => {
  const {
    onConfirmedFiltersChange,
    onFilterConfirm = () => {},
    onFilterChipClose = () => {},
    customToolbarSelect,
  } = options;
  const newOptions = {
    ...options,
    onFilterConfirm: (filterList, ...rest) => {
      onFilterConfirm(filterList, rest);
      if (onConfirmedFiltersChange) {
        onConfirmedFiltersChange(mapfilterListtoMap(filterList, columns));
      }
    },
    onFilterChipClose: (index, removedFilter, filterList, ...rest) => {
      onFilterChipClose(index, removedFilter, filterList, ...rest);
      if (onConfirmedFiltersChange) {
        onConfirmedFiltersChange(mapfilterListtoMap(filterList, columns));
      }
    },
  };
  if (customToolbarSelect) {
    newOptions.customToolbarSelect = (selectedRows, ...params) => {
      selectedRows.data.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        item.row = data[item.dataIndex];
      });
      return customToolbarSelect(selectedRows, ...params);
    };
  }
  return newOptions;
};

const useStyles = makeStyles({
  table: {
    display: "flex",
    flexDirection: "column",
    boxShadow: "none",
    "& th": {
      textTransform: "capitalize",
      padding: "14px !important",
    },
    "& td": {
      padding: "12px !important",
      wordBreak: "keep-all",
    }
  },
  tableContainer: {
    overflow: "auto",
    "&::-webkit-scrollbar": {
      height: "2px",
    },
  },
});

export default ({ title, data, columns, options, className, ...props }) => {
  const classes = useStyles(props);
  const mappedColumns = mapColumns(columns, data);
  const newOptions = {
    ...options,
    textLabels: {
      ...options.textLabels,
      filter: {
        title:
          typeof title === "string"
            ? `filter ${title.replace("All", "")} as you need`
            : "filter",
        all: "All",
        reset: "reset",
      },
    },
  };
  return (
    <div className={classes.tableContainer}>
      <MUIDataTable
        title={title}
        data={data}
        columns={mappedColumns}
        options={mapOptions(newOptions, columns, data)}
        className={classNames(className, classes.table)}
        {...props}
      />
    </div>
  );
};
